/* Gogo Main Style

Table of Contents

00.Variables and Imports
01.Base
09.Navbar
10.Menu
15.Main
38.Tables
*/

/* 00.Variables and Imports */
@import "./_palette.scss";

// $main-menu-team-card-height: 110px;
$main-menu-width: 120px;
$main-menu-width-lg: 110px;
$main-menu-width-md: 100px;
$main-menu-width-xs: 90px;

$sub-menu-width: 230px;
$sub-menu-width-lg: 230px;
$sub-menu-width-md: 230px;
$sub-menu-width-xs: 230px;

$navbar-height: 83px;

$main-margin: 60px;
$main-margin-lg: 50px;
$main-margin-md: 40px;
$main-margin-xs: 15px;

$menu-collapse-time: 300ms;

$border-radius: 0.1rem;

$theme-color-green: #0a3232;
$success-color: $primaryBlue;
$error-color: #ff5151;

/* 01.Base */
html {
  width: 100%;
  height: 100%;
  background: $background-color;
}

#root {
  height: 100%;
}

body {
  font-family: "Roobert-Regular", sans-serif;
  font-size: 0.8rem;
  font-weight: 400;
  color: $primary-color;
  background: $background-color;

  &.background {
    height: 100%;

    main {
      margin: 0 !important;
      height: 100%;

      .container {
        height: 100%;
      }
    }
  }
}

h1 {
  font-size: 36px;
  padding-bottom: 10px;
  display: inline-block;
  font-family: "Roobert-SemiBold";

  @include respond-below(sm) {
    font-size: 1.3rem;
  }
}

h2 {
  font-size: 1.4rem;

  @include respond-below(sm) {
    font-size: 1.1rem;
  }
}

h3 {
  font-size: 1.3rem;

  @include respond-below(sm) {
    font-size: 1rem;
  }
}

h3 {
  font-size: 1.2rem;

  @include respond-below(sm) {
    font-size: 1rem;
  }
}

h4 {
  font-size: 1.15rem;

  @include respond-below(sm) {
    font-size: 0.9rem;
  }
}

h5 {
  font-size: 1.1rem;

  @include respond-below(sm) {
    font-size: 0.9rem;
  }
}

h6 {
  font-size: 1rem;

  @include respond-below(sm) {
    font-size: 0.85rem;
  }
}

a {
  color: $primary-color;
  transition: color 200ms;

  &:hover,
  &:active {
    text-decoration: initial;
    color: $theme-color-1;
  }
}

p {
  font-size: 0.85rem;
  line-height: 1.3rem;
  font-family: "Roobert-Regular", sans-serif;
}

/* 02.Buttons */

input[type="checkbox"] {
  cursor: pointer;
}

.triangle-color {
  filter: invert(57%) sepia(74%) saturate(5058%) hue-rotate(325deg)
    brightness(98%) contrast(116%);
}

.phone-color {
  filter: invert(14%) sepia(14%) saturate(2257%) hue-rotate(130deg)
    brightness(94%) contrast(94%);
}

.wifi-color {
  filter: invert(54%) sepia(93%) saturate(6274%) hue-rotate(237deg)
    brightness(108%) contrast(94%);
  margin-bottom: 5px;
}

/* 09.Navbar*/

.navbar {
  background: white;
  height: $navbar-height;
  padding: 1.5rem 0;
  @include depth(1);

  .menu-button-mobile {
    color: $secondary-color;
    text-align: center;
    margin-left: $main-margin-xs;
    svg {
      height: 12px;
      fill: $primary-color;
    }

    @include respond-below(sm) {
      width: 20px;
      margin-bottom: 13px;
    }
  }

  .menu-button-mobile-admin {
    color: $secondary-color;
    text-align: center;
    margin-left: $main-margin-xs;
    svg {
      height: 12px;
      fill: $primary-color;
    }

    @include respond-below(sm) {
      width: 20px;
    }
  }

  .menu-button {
    color: $button-text-color;
    width: $main-menu-width;
    text-align: center;

    svg {
      height: 12px;
    }

    .main {
      fill: $soft-black;
      transition: fill $menu-collapse-time;
      width: 10px;
    }

    .sub {
      fill: $soft-black;
      transition: fill $menu-collapse-time;
    }

    &:hover {
      color: $button-text-color;
    }

    @include respond-below(xl) {
      width: $main-menu-width-lg;
    }

    @include respond-below(lg) {
      width: $main-menu-width-md;
    }

    @include respond-below(sm) {
      width: 60px;
    }
  }

  .navbar-left {
    flex-basis: 40%;
    margin-top: -9px;

    @include respond-below(lg) {
      margin-top: -2px;
    }

    @include respond-below(sm) {
      margin-top: 10px;
    }
  }

  .navbar-right {
    flex-basis: 40%;
    text-align: right;
    margin-top: -10px;

    @include respond-below(lg) {
      margin-top: -3px;
    }
  }

  .switch-account:hover {
    color: $primaryBlue;
  }

  .navbar-logo {
    width: 190px;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;

    .logo {
      width: 100%;
      height: 100%;
      background: url($logoPathSmall) no-repeat;
      background-position: center center;
      @include respond-below(sm) {
        background-size: 100%;
        right: 22%;
        width: 145px;
      }
      @include respond-below(xs) {
        background-size: 100%;
        right: 10%;
        width: 145px;
      }
    }
  }

  @include respond-below(xl) {
    height: $navbar-height;
  }

  @include respond-below(lg) {
    height: $navbar-height;
    padding: 1rem 0 1rem 0;
  }

  @include respond-below(sm) {
    height: $navbar-height;
    padding: 10px 0;
  }
}

.sub-hidden .menu-button,
.menu-sub-hidden .menu-button {
  .sub {
    fill: $soft-black;
  }
}

.main-hidden .menu-button,
.menu-hidden .menu-button {
  .main,
  .sub {
    fill: $soft-black;
  }
}

/* 10.Menu*/
.sidebar {
  padding-top: $navbar-height;
  z-index: 4;
  transition: border-radius $menu-collapse-time;

  .main-menu {
    &.main-hidden {
      transform: translateX(-$main-menu-width);
    }

    ul li {
      i {
        font-size: 32px;
        line-height: 42px;
      }
      &.active:after {
        content: " ";
        background: $primaryBlue;
        border-radius: 10px;
        position: absolute;
        width: 7px;
        height: 90px;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
      }

      &:last-of-type {
        a {
          border-bottom: initial;
        }
      }
    }

    @include respond-below(xl) {
      width: $main-menu-width-lg;
    }

    @include respond-below(lg) {
      width: $main-menu-width-md;
    }

    @include respond-below(sm) {
      width: $main-menu-width-lg;

      ul li i {
        font-size: 30px;
        line-height: 38px;
      }

      ul li a {
        height: 90px;
      }

      ul li.active:after {
        width: 3px;
        height: 60px;
      }
    }
  }
}

//Menu Config Main Menu Sub Menu

.sub-hidden .sub-menu,
.menu-sub-hidden .sub-menu,
.menu-hidden .sub-menu {
  transform: translateX(-$sub-menu-width);

  @include respond-below(xl) {
    transform: translateX(-$sub-menu-width-lg);
  }

  @include respond-below(lg) {
    transform: translateX(-$sub-menu-width-md);
  }

  @include respond-below(sm) {
    transform: translateX(-$sub-menu-width-xs);
  }
}

.main-hidden .main-menu,
.menu-hidden .main-menu {
  transform: translateX(-$main-menu-width);

  @include respond-below(xl) {
    transform: translateX(-$main-menu-width-lg);
  }

  @include respond-below(lg) {
    transform: translateX(-$main-menu-width-md);
  }

  @include respond-below(sm) {
    transform: translateX(-$main-menu-width-xs);
  }
}

.main-hidden.sub-hidden .sub-menu,
.menu-hidden .sub-menu {
  transform: translateX(-$sub-menu-width - $main-menu-width);

  @include respond-below(xl) {
    transform: translateX(-$sub-menu-width-lg - $main-menu-width-lg);
  }

  @include respond-below(lg) {
    transform: translateX(-$sub-menu-width-md - $main-menu-width-md);
  }

  @include respond-below(sm) {
    transform: translateX(-$sub-menu-width-xs - $main-menu-width-xs);
  }
}

//Mobile
.menu-mobile {
  .main-menu {
    transform: translateX(-$main-menu-width-lg);
  }

  .sub-menu {
    transform: translateX(-$sub-menu-width-xs - $main-menu-width-xs - 50);
  }
}

//Show Temporary
.main-show-temporary {
  .main-menu {
    transform: translateX(0);
  }

  .sub-menu {
    transform: translateX(-$sub-menu-width);
  }

  @include respond-below(xl) {
    .main-menu {
      transform: translateX(0);
    }

    .sub-menu {
      transform: translateX(-$sub-menu-width-lg);
    }
  }

  @include respond-below(lg) {
    .main-menu {
      transform: translateX(0);
    }

    .sub-menu {
      transform: translateX(-$sub-menu-width-md);
    }
  }

  @include respond-below(sm) {
    .sub-menu {
      transform: translateX(-$sub-menu-width-xs);
    }
  }
}

.sub-show-temporary,
.menu-mobile.sub-show-temporary {
  .sub-menu {
    transform: translateX(0);
  }
}

/* 15.Main*/
main {
  margin-top: $navbar-height + $main-margin;

  @include respond-below(sm) {
    margin-left: $main-margin-xs !important;
    margin-right: $main-margin-xs !important;
    margin-bottom: 0;
  }
}

//Menu Config Main

.sub-hidden main,
.menu-sub-hidden main,
.menu-hidden main {
  margin-left: $main-menu-width + $main-margin;
}

.main-hidden main,
.menu-hidden main {
  margin-left: $main-margin;
  margin-right: $main-margin;
}

@include respond-below(xl) {
  .sub-hidden main,
  .menu-sub-hidden main,
  .menu-hidden main {
    margin-left: $main-menu-width-lg + $main-margin-lg;
  }

  .main-hidden main,
  .menu-hidden main {
    margin-left: $main-margin-lg;
  }
}

@include respond-below(lg) {
  .sub-hidden main,
  .menu-sub-hidden main,
  .menu-hidden main {
    margin-left: $main-menu-width-md + $main-margin-md;
  }

  .main-hidden main,
  .menu-hidden main {
    margin-left: $main-margin-md;
  }
}

/* 38.Tables */

.table {
  color: $efblack;
  font-size: 16px;
}

.table th,
.table td {
  border-color: $separator-color-light !important;
}

input[type="checkbox"].owl-checkbox {
  display: none;
  &:hover {
    cursor: pointer;
  }
}

.owl-checkbox-label {
  border: 1px solid #8c8c8c;
  display: inline-block;
  border-radius: 2px;
  height: 12px;
  width: 12px;
  &:hover {
    cursor: pointer;
  }
}
input[type="checkbox"].owl-checkbox:checked + .owl-checkbox-label {
  background: $primaryBlue;
  border-color: $primaryBlue;
  color: #fff;
  background-image: url("../../../../public/assets/img/check.svg");
  background-repeat: no-repeat;
  background-position: center;
  &:hover {
    cursor: pointer;
  }
}

input[type="checkbox"].owl-checkbox:checked + .table-dash-line {
  background-image: url("../../../../public/assets/img/check-line.svg");
}

// Added appearance:auto, Tailwind doesn't have this property
input[type="radio"] {
  appearance: auto;
}

//Keep for table vertical shadow (for scroll-shadow-element package)
scroll-shadow {
  display: block;
  --scroll-shadow-size: 8;
  --scroll-shadow-top: linear-gradient(
    rgba(0, 0, 0, 0.1),
    rgba(255, 255, 255, 0)
  );
  --scroll-shadow-bottom: linear-gradient(
    rgba(255, 255, 255, 0),
    rgba(0, 0, 0, 0.1)
  );
}

//Keep for table horizontal shadow
.table-horizontal-shadow {
  background-image: linear-gradient(to right, white, white),
    linear-gradient(to right, white, white),
    linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0)),
    linear-gradient(to left, rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0)) !important;
  background-position: left center, right center, left center, right center;
  background-size: 8px 100%, 8px 100%, 9px 100%, 9px 100%;
  background-attachment: local, local, scroll, scroll;
}

//TO DO: fix in OUICLE table component
@media (max-width: 767px) {
  .table,
  th,
  .table td {
    border: none !important;
  }
}
