// TODO: Unify all colors throughout the app in this file
// Then, export this file from OUI and import here

// TODO: Do not use any of these directly; use theme colors.

$primaryGrey: #373e40;
$primaryLightGrey: #d5d5d5;
$primaryBlue: #4B50F8;

$olShadeBlue: #363A9A;
$olShadeBlueLight: #C6C8F8;

$secondaryRed: #FF5151;
$secondaryGrey: #d6e1e5;
$secondaryGreen: #0bd3b6;
$secondaryOrange: #FFB280;

$systemGreen: #008515;
$systemGreenLight: #C4EBC4;
$activeGreen: rgb(44, 148, 44);

$gray300: #d2d2d2;
$gray500: #8C8C8C;
$gray700: #525252;
$secondaryDarkGrey: #919798;
$secondaryLightGrey: #f3f5f6;
$formGrayBorder: rgba(214, 225, 229, 0.4);
$grayBorder: rgba(214, 225, 229, 0.25);
$inputPlaceholderGrey: #8c8c8c;

$tableRowAlternate: #f3f5f6;
$hoverAttention: $secondaryGrey;
$formColor: #768589;
$tableRowSelectColor: rgba(75, 80, 248, 0.12);


$white: #fff;
$offWhite: #f4f4f4;

$black: #000000;
$efblack: #141414;

