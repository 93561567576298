@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Roobert-Regular";
  src: url("./assets/fonts/Roobert-Regular.otf") format("openType");
  src: url("./assets/fonts/roobert-regular.woff") format("woff");
  src: url("./assets/fonts/roobert-regular.woff2") format("woff2");
}

@font-face {
  font-family: "Roobert-RegularItalic";
  src: url("./assets/fonts/Roobert-RegularItalic.otf") format("openType");
}

@font-face {
  font-family: "Roobert-Medium";
  src: url("assets/fonts/Roobert-Medium.otf") format("openType");
}

@font-face {
  font-family: "Roobert-MediumItalic";
  src: url("./assets/fonts/Roobert-MediumItalic.otf") format("openType");
}

@font-face {
  font-family: "Roobert-SemiBold";
  src: url("./assets/fonts/Roobert-SemiBold.otf") format("openType");
}

@font-face {
  font-family: "Roobert-SemiBoldItalic";
  src: url("./assets/fonts/Roobert-SemiBoldItalic.otf") format("openType");
}

@font-face {
  font-family: "Right-Grotesk";
  src: url("./assets/fonts/rightgrotesk-narrowblack.woff2") format("woff2"),
    url("./assets/fonts/rightgrotesk-narrowblack.woff") format("woff");
}
